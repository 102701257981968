.field-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  outline: none;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked ~ .field-checkbox__mark {
      &::before {
        opacity: 1;
      }
    }
  }

  &__mark {
    display: inline-block;
    flex: 0 0 auto;
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid #eaeaea;
    background-color: #fff;

    &::before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      background-color: #000;
      top: 50%;
      left: 50%;
      margin: -3px 0 0 -3px;
      opacity: 0;
      transition: .3s;
    }
  }

  &__title {
    padding-left: 10px;
    color: #686868;
    font-family: Formular;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
  }
}