.card {
  &__content {
    padding: 25px 0;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;

    @include r(900) {
      display: block;
    }
  }

  &__gallery {
    flex: 0 0 auto;
    max-width: 600px;
    width: 100%;
    display: block;

    @include r(1300) {
      max-width: 500px;
    }
    @include r(1100) {
      max-width: 400px;
    }
    @include r(900) {
      margin: 0 auto;
      max-width: 500px;
    }

    &-photo {
      display: block;
      width: 100%;
      height: 100%;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center top;
      }
      cursor: pointer;
    }
    &-recommend {
      width: 80px;
      height: 80px;
      background-color: #fff;
      border-radius: 50%;
      display: block;
      text-align: center;
      position: absolute;
      color: #686868;
      font-family: Formular;
      font-size: 9px;
      font-weight: 400;
      line-height: 80px;
      text-transform: uppercase;
      top: 6px;
      right: 6px;
      z-index: 2;
    }
    .gallery-top {
      height: 449px;
      @include r(1300) {
        height: 350px;
      }
      @include r(550) {
        height: 250px;
      }
    }
    .gallery-thumbs {
      margin-top: 22px;
      height: 98px;
      @include r(550) {
        height: 70px;
        margin-top: 15px;
      }
    }
  }

  &__name {
    display: block;
    color: #232323;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.2;

    @include r(767) {
      font-size: 24px;
    }
  }

  &__price {
    display: block;
    color: #232323;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;

    @include r(767) {
      font-size: 22px;
    }
  }

  &__description {
    flex: 1 1 auto;
    padding-left: 50px;
    @include r(1300) {
      padding-left: 30px;
    }
    @include r(900) {
      padding: 30px 0 0;
    }
    &-status {
      display: block;
      align-items: center;
      margin-top: 15px;

      img {
        max-width: 17px;
        position: relative;
        top: 2px;
      }

      span {
        display: inline-block;
        color: #0f8016;
        font-family: Formular;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        margin-left: 5px;
      }
    }
  }

  &__characteristics {
    display: block;
    margin-top: 20px;
    max-width: 625px;

    &-string {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }
      &:nth-child(even) {
        background-color: #fff;
      }
    }

    &-title {
      flex: 0 0 240px;
      padding: 0 18px;
      color: #686868;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;

      @include r(550) {
        flex: 0 0 150px;
      }
    }

    &-description {
      flex: 1 1 auto;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  &__information {
    display: block;
    margin-top: 30px;
    padding: 20px 0;
    border: {
      width: 1px 0;
      style: solid;
      color: #eaeaea;
    }
  }

  &__video {
    display: block;
    max-width: 457px;
    width: 100%;
    margin: 14px 0 0 -7px;

    iframe {
      width: 100%;
      height: 300px;
    }
  }
}