.hero {
  position: relative;

  &::before {
    width: 1360px;
    height: 550px;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background: {
      image: url("#{$images-path}/bg-hero.jpg");
      repeat: no-repeat;
      size: contain;
    }
    z-index: 1;
    @include r(767) {
      display: none;
    }
  }

  &__slider {
    display: block;
    position: relative;
    height: 500px;
    z-index: 2;
    padding-left: 160px;
    @include r(1000) {
      padding-left: 0;
      height: 400px;
    }
    @include r(767) {
      height: 300px;
    }
  }

  &__slide {
    height: 100%;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    bottom: 0;
    z-index: 3;
    @include r(767) {
      right: 0;
    }

    .hero__slider-swiper-button-next {
      width: 62px;
      height: 62px;
      border: 1px solid #ccc;
      border-radius: 50%;
      background: {
        image: url("#{$images-path}/right-arrow.svg");
        repeat: no-repeat;
        position: center;
        size: 12px;
      }
      position: absolute;
      right: 0;
      bottom: 30px;
      cursor: pointer;
      transition: .3s;

      @include r(767) {
        bottom: 10px;
        width: 50px;
        height: 50px;
        right: calc(50% - 55px);
      }

      &:hover {
        opacity: .8;
      }
    }
    .hero__slider-swiper-button-prev {
      width: 62px;
      height: 62px;
      border: 1px solid #ccc;
      border-radius: 50%;
      background: {
        image: url("#{$images-path}/left-arrow.svg");
        repeat: no-repeat;
        position: center;
        size: 12px;
      }
      position: absolute;
      right: 88px;
      bottom: 30px;
      cursor: pointer;
      transition: .3s;
      &:hover {
        opacity: .8;
      }
      @include r(767) {
        bottom: 10px;
        right: inherit;
        left: calc(50% - 55px);
        width: 50px;
        height: 50px;
      }
    }
    .hero__slider-swiper-pagination {
      position: absolute;
      bottom: 46px;
      right: 190px;
      max-height: 220px;
      width: auto;
      z-index: 3;
      left: inherit;
      text-align: right;
      color: transparent;

      @include r(767) {
        display: none;
      }

      .swiper-pagination-current, .swiper-pagination-total {
        color: #c9c9c9;
        font-family: Formular;
        font-size: 23px;
        font-weight: 400;
      }
      .swiper-pagination-current {
        margin-right: 75px;
        position: relative;
        &::before {
          content: '';
          width: 110px;
          height: 2px;
          position: absolute;
          top: 50%;
          left: calc(100% + 31px);
          margin-top: -1px;
          background: {
            image: url("#{$images-path}/pagination-line.png");
            repeat: no-repeat;
            size: contain;
          }
        }
      }
      .swiper-pagination-total {
        margin-left: 75px;
      }
    }
  }
}