.catalog {

  &__content {
    padding: 20px 0 100px 0;
  }

  &__container {
    display: flex;
    align-items: flex-start;
  }

  &__filter {
    display: inline-block;
    flex: 0 0 auto;
    max-width: 270px;

    @include r(920) {
      max-width: 250px;
    }
    @include r(700) {
      max-width: 220px;
    }
    @include r(520) {
      max-width: 150px;
    }

    &-inner {
      display: block;
      padding: 18px 22px;
      border-bottom: 1px solid #eaeaea;

      @include r(700) {
        padding: 14px 10px;
      }
      @include r(520) {
        padding: 14px 0;
      }
    }

    &-items {
      display: block;
    }
    &-item {
      margin-top: 18px;
    }
  }

  &__products {
    flex: 1 1 auto;
    padding: 32px 0 0 55px;
    @include r(920) {
      padding: 30px 0 0 30px;
    }
    @include r(520) {
      padding: 30px 0 0 10px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include r(920) {
        display: block;
      }
    }

    &-sorting {
      display: flex;
      align-items: center;
      @include r(920) {
        margin-top: 5px;
      }
      @include r(650) {
        display: block;
      }

      ul {
        display: flex;
        align-items: center;
        margin-left: 20px;

        @include r(650) {
          margin-top: 10px;
        }
        @include r(430) {
          flex-wrap: wrap;
          margin-left: -5px;
        }

        li {
          display: inline-block;
          margin-left: 20px;
          @include r(430) {
            margin: 5px;
          }

          &:first-child {
            margin-left: 0;

            @include r(430) {
              margin-left: 5px;
            }
          }

          .main {
            &__text {
              position: relative;
              cursor: pointer;
              padding-right: 15px;
              transition: .3s;

              &:hover {
                opacity: .7;
              }

              &.is-active {
                &::after {
                  transform: rotate(180deg);
                }
              }

              &::after {
                position: absolute;
                top: 50%;
                right: 0;
                width: 9px;
                height: 13px;
                margin-top: -6px;
                content: '';
                background: {
                  image: url("#{$images-path}/arrow-sorting.png");
                  repeat: no-repeat;
                  size: contain;
                }
              }
            }
          }
        }
      }
    }
    &-inners {
      display: flex;
      align-items: flex-start;
      margin: 0 -20px;
      flex-wrap: wrap;
      @include r(920) {
        margin: 0 -10px;
      }
    }
    &-inner {
      display: block;
      padding: 30px 20px;
      flex: 0 0 25%;

      @include r(1200) {
        flex: 0 0 33.3333333%;
      }
      @include r(1000) {
        flex: 0 0 50%;
      }
      @include r(920) {
        padding: 20px 10px;
      }
      @include r(700) {
        flex: 0 0 100%;
      }

      &:hover {
        .catalog {
          &__link {
            text-decoration: underline;
          }
        }
      }
    }
    &-photo {
      display: block;
      height: 210px;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }
  }
}