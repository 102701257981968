.header {
  position: relative;
  z-index: 5;

  &__mob {
    display: none;
    align-items: center;
    justify-content: space-between;
    background-color: #232323;
    padding: 2px 10px;
    position: relative;
    z-index: 2;

    @include r(870) {
      display: flex;
    }
    .header {
      &__phone {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }

  &__navigation {
    @include r(870) {
      transform: translateY(-100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: .5s;
      background-color: #fff;
      z-index: 1;
      padding: 70px 20px 60px 20px;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);

      &.is-open {
        transform: translateY(0);
      }
    }
  }

  &__burger {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      content: '';
      top: 6px;
      left: 50%;
      width: 26px;
      height: 2px;
      margin-left: -13px;
      background-color: #fff;
      transition: .5s;
    }

    span {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 26px;
      height: 2px;
      margin: -1px 0 0 -13px;
      background-color: #fff;
      transition: .5s;
    }

    &::after {
      position: absolute;
      content: '';
      bottom: 6px;
      left: 50%;
      width: 26px;
      height: 2px;
      margin-left: -13px;
      background-color: #fff;
      transition: .5s;
    }

    &.is-open {
      &::before, &::after {
        opacity: 0;
      }
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(0, 0);
        }
        &:nth-child(2) {
          transform: rotate(-45deg) translate(0, 0);
        }
      }
    }
  }

  &__top {
    .header {
      &__content {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include r(870) {
          display: block;
          padding: 0;
        }
      }

      &__menu {
        display: flex;
        align-items: center;
        @include r(870) {
          display: block;
          text-align: center;
        }

        li {
          display: inline-block;
          margin-right: 40px;

          @include r(1000) {
            margin-right: 20px;
          }
          @include r(870) {
            display: block;
            margin: 10px;
          }

          a {
            display: block;
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 400;
            @include r(870) {
              color: #232323;
              font-size: 16px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &__rside {
        display: flex;
        align-items: center;
        @include r(870) {
          display: none;
        }
      }

      &__form {
        display: inline-block;
        margin-right: 36px;
      }

      &__search {
        display: block;
        width: 334px;
        height: 26px;
        background-color: transparent;
        font-family: 'Formular', sans-serif;
        color: #232323;
        font-size: 14px;
        font-weight: 400;
        border: {
          width: 0 0 1px 0;
          style: solid;
          color: #9e9e9e;
        }
        transition: .3s;

        @include r(1000) {
          width: 260px;
        }

        &::placeholder {
          color: #9e9e9e;
        }
        &:hover {
          border-bottom-color: #232323;
        }
        &:focus {
          border-bottom-color: #232323;
        }
      }

      &__phone {
        display: inline-block;
        color: #232323;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
      }
    }
  }

  &__bottom {
    background-color: #232323;
    @include r(870) {
      background-color: transparent;
    }
    .header {
      &__content {
        padding: 1px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include r(870) {
          display: block;
          text-align: center;
          padding: 0;
        }
      }

      &__logo {
        margin-right: 100px;

        @include r(1100) {
          margin-right: 50px;
        }

        @include r(1000) {
          margin-right: 20px;
        }
        @include r(870) {
          display: none;
        }
      }

      &__lside {
        display: flex;
        align-items: center;
        @include r(870) {
          display: block;
        }
      }
      &__menu {
        display: flex;
        align-items: center;
        @include r(870) {
          display: block;
          text-align: center;
        }

        li {
          display: inline-block;
          margin-right: 40px;

          @include r(1000) {
            margin-right: 20px;
          }
          @include r(870) {
            display: block;
            margin: 10px;
          }

          a {
            display: block;
            color: #fff;
            font-size: 16px;
            font-weight: 400;

            @include r(870) {
              color: #232323;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &__callback {
        display: block;
        position: relative;
        padding-left: 24px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        @include r(870) {
          display: inline-block;
          text-align: center;
          padding-left: 0;
          color: #232323;
          position: relative;
        }

        &:hover {
          text-decoration: underline;
        }

        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 21px;
          background: {
            image: url("#{$images-path}/icon-phone.png");
            repeat: no-repeat;
            size: contain;
          }
          @include r(870) {
            left: -36px;
          }
        }
      }
    }
  }
}