.breadcrumbs {
  display: flex;
  align-items: center;

  li {
    display: inline-block;
    padding-right: 12px;
    position: relative;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      position: absolute;
      content: '·';
      color: #686868;
      font-size: 16px;
      font-weight: 400;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}