.btn-green {
  color: #fff;
  font-family: Formular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  max-width: 280px;
  width: 100%;
  height: 50px;
  background-color: #318a35;
  text-align: center;
  padding: 16px 10px;
  transition: .3s;

  &:hover {
    background-color: #226425;
  }
}