// General
@import "general/variables";
@import "general/mixins";
@import "vendors/normalize";
@import "general/fonts";
@import "general/base";

// Vendors
@import "vendors/swiper.min";

// Components
@import "components/main";
@import "components/checkbox";
@import "components/breadcrumbs";
@import "components/button";

@import "components/header";
@import "components/hero";
@import "components/new-catalog";
@import "components/news";
@import "components/subscribe";
@import "components/catalog";
@import "components/card";



@import "components/footer";

// Helpers
@import "helpers/animations";