.main {
  position: relative;

  &__title {
    display: block;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.1;

    @include r(767) {
      font-size: 22px;
    }
  }

  &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
  }
}

.fw-bold {
  font-weight: 700;
}

.fs-sm {
  font-size: 14px;
}

.fs-xs {
  font-size: 12px;
}

.color-white {
  color: #fff;
}

.color-dark {
  color: #232323;
}

.color-gray {
  color: #686868;
}

.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.link-none {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}