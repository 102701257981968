.new-catalog {

  &__content {
    padding: 95px 0 30px 0;

    @include r(1000) {
      padding: 40px 0 30px 0;
    }
  }

  &__slider {
    display: block;
    position: relative;
    padding-bottom: 120px;
    margin-top: 26px;

    @include r(767) {
      padding-bottom: 80px;
    }

    &-swiper-button-next {
      width: 62px;
      height: 62px;
      border: 1px solid #ccc;
      border-radius: 50%;
      background: {
        image: url("#{$images-path}/right-arrow-gray.svg");
        repeat: no-repeat;
        position: center;
        size: 12px;
      }
      position: absolute;
      right: 0;
      bottom: 30px;
      cursor: pointer;
      transition: .3s;

      @include r(767) {
        bottom: 10px;
        width: 50px;
        height: 50px;
        right: calc(50% - 55px);
      }

      &:hover {
        opacity: .8;
      }
    }
    &-swiper-button-prev {
      width: 62px;
      height: 62px;
      border: 1px solid #ccc;
      border-radius: 50%;
      background: {
        image: url("#{$images-path}/left-arrow-gray.svg");
        repeat: no-repeat;
        position: center;
        size: 12px;
      }
      position: absolute;
      right: 88px;
      bottom: 30px;
      cursor: pointer;
      transition: .3s;
      @include r(767) {
        bottom: 10px;
        right: inherit;
        left: calc(50% - 55px);
        width: 50px;
        height: 50px;
      }
      &:hover {
        opacity: .8;
      }
    }
    &-swiper-pagination {
      position: absolute;
      bottom: 52px;
      right: 190px;
      max-height: 220px;
      width: auto;
      z-index: 3;
      left: inherit;
      text-align: right;
      color: transparent;

      @include r(767) {
        display: none;
      }

      .swiper-pagination-current, .swiper-pagination-total {
        color: #232323;
        font-family: Formular;
        font-size: 14px;
        font-weight: 400;
      }
      .swiper-pagination-current {
        margin-right: 70px;
        position: relative;
        &::before {
          content: '';
          width: 95px;
          height: 1px;
          position: absolute;
          top: 50%;
          left: calc(100% + 32px);
          background: {
            image: url("#{$images-path}/pagination-line-2.png");
            repeat: no-repeat;
            size: contain;
          }
        }
      }
      .swiper-pagination-total {
        margin-left: 70px;
      }
    }
  }

  &__slide {
    display: block;

    &:hover {
      .new {
        &-catalog {
          &__link {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__photo {
    display: block;
    height: 210px;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  }
}