.news {
  overflow-x: hidden;
  &__content {
    padding: 30px 0 80px 0;

    @include r(1000) {
      padding: 30px 0 60px 0;
    }
  }

  &__inners {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -65px;
    @include r(1500) {
      margin: 0 -40px;
    }
    @include r(1200) {
      display: block;
      margin: 0;
    }
  }

  &__inner {
    flex: 0 0 calc(50% - 130px);
    margin: 28px 65px 0 65px;
    display: flex;
    align-items: flex-start;

    @include r(1500) {
      flex: 0 0 calc(50% - 80px);
      margin: 28px 40px 0 40px;
    }
    @include r(1200) {
      margin: 28px 0 0 0;
    }
    @include r(680) {
      display: block;
    }

    &:hover {
      .news {
        &__link {
          text-decoration: underline;
        }
      }
    }
  }

  &__photo {
    position: relative;
    padding: 0 37px 43px 37px;
    flex: 0 0 auto;

    @include r(680) {
      max-width: 324px;
      margin: 0 auto;
    }

    &-img {
      display: block;
      position: relative;
      z-index: 2;
      width: 250px;
      height: 200px;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }
    }

    &::after {
      width: 100%;
      height: 110px;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      background-color: #f8f8f8;
      z-index: 1;
    }
  }

  &__description {
    flex: 1 1 auto;
    @include r(1600) {
      padding-left: 20px;
    }
    @include r(680) {
      text-align: center;
      padding: 20px 0 0 0;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;

    @include r(680) {
      display: block;
      text-align: center;
      margin-top: 20px;
    }
  }

  &__bottom {
    display: block;
    max-width: 574px;
    margin: 30px auto 0 auto;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      right: calc(100% + 30px);
      top: 50%;
      width: 682px;
      height: 224px;
      transform: translateY(-50%);
      background: {
        image: url("#{$images-path}/bg-news-decor.jpg");
        repeat: no-repeat;
        size: contain;
      }

      @include r(1000) {
        display: none;
        text-align: center;
      }
    }
  }
}