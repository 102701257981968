.subscribe {
  overflow-x: hidden;

  @include r(980) {
    background-color: #232323;
    overflow: inherit;
  }

  &__content {
    padding: 40px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include r(980) {
      display: block;
      text-align: center;
      padding: 30px 0;
    }

    &::before {
      position: absolute;
      content: '';
      width: 100vw;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #232323;
      z-index: 1;

      @include r(980) {
        display: none;
      }
    }

    .main {
      &__title {
        position: relative;
        z-index: 2;
        flex: 0 0 auto;
        max-width: 460px;

        @include r(980) {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  &__form {
    position: relative;
    z-index: 2;
    flex: 0 0 630px;
    margin-right: 140px;

    @include r(1500) {
      flex: 0 0 530px;
      margin-right: 100px;
    }
    @include r(1300) {
      flex: 0 0 500px;
      margin-right: 20px;
    }
    @include r(1100) {
      flex: 0 0 400px;
    }
    @include r(980) {
      max-width: 500px;
      margin: 40px auto 0 auto;
    }

    input {
      display: block;
      width: 100%;
      height: 26px;
      padding: 0 130px 0 0;
      background-color: transparent;
      font-family: 'Formular', sans-serif;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: #9e9e9e;
      }
      transition: .3s;

      &::placeholder {
        color: #aeaeae;
      }
      &:hover {
        border-bottom-color: #fff;
      }
      &:focus {
        border-bottom-color: #fff;
      }
    }
    button {
      position: absolute;
      top: 5px;
      right: 0;
      color: #fff;
      font-family: Formular;
      font-size: 14px;
      font-weight: 400;
      z-index: 2;
      transition: .3s;

      &:hover {
        opacity: .8;
      }
    }
  }
}