.footer {

  &__content {
    padding: 30px 0 30px 0;
  }

  &__inners {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 -40px;
    @include r(800) {
      flex-wrap: wrap;
      margin: 0 -20px;
    }
  }

  &__inner {
    padding: 20px 40px;

    @include r(800) {
      flex: 0 0 50%;
      padding: 10px 20px;
      text-align: center;
    }
    @include r(400) {
      flex: 0 0 100%;
    }

    ul {
      display: flex;
      align-items: center;
      @include r(800) {
        justify-content: center;
      }
      li {
        display: inline-block;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        a {
          transition: .3s;
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }

  &__bottom {
    display: block;
    padding-top: 30px;
    margin-top: 20px;
    border-top: 1px solid #f2f2f2;
    @include r(800) {
      text-align: center;
    }
  }
}